import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import axios from "../config/Axios";
import {SHOW_PREFERENCES, SHOW_DIAMOND_GIRLS, SHOW_VIP_DISCOUNT, SHOW_VERIFY} from "../config/Constants";
import {modalActions} from "../store/modal";

const showDiamondMembershipReminder = (u, url) => {
    if (u?.sex !== 'female') return false;
    if (url.includes('/membership')) return false;
    if (u.membership.isPremium) return false;
    if (!u.additional.prices.canUseTrial) return false;

    const setItem = (dt) =>  localStorage.setItem(SHOW_DIAMOND_GIRLS, JSON.stringify(dt));

    const currentTimestamp = Date.now();
    const defaultData = {checkedAt: null, showedAt: null}
    const dataToParse = localStorage.getItem(SHOW_DIAMOND_GIRLS) ?? null;
    const data = dataToParse ? JSON.parse(dataToParse) : defaultData;

    // If not check - check and do not show
    if (!data.checkedAt) {
        data.checkedAt = currentTimestamp;
        setItem(data);
        return false;
    }

    // Do not show popup for the first day
    const oneDayInMillis = 1 * 24 * 60 * 60 * 1000;
    if (data.checkedAt && oneDayInMillis > (currentTimestamp - data.checkedAt)) {
        return false;
    }

    // Do not shop popup for 7 days after it was seen.
    const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000;
    if (data.showedAt && oneWeekInMillis > (currentTimestamp - data.showedAt)) {
        return false;
    }

    data.showedAt = currentTimestamp;
    setItem(data);
    return true;
}

const showVipDiscountOffer = (u, url) => {
    if (u?.sex !== 'male') return false;
    if (url.includes('/membership')) return false;
    if (u.membership.isPremium) return false;
    if (u.additional.prices.plan !== 'default') return false;
    if (u.additional.prices.list.hasOwnProperty('diamond') === false) return false;

    const plan = u.additional.prices.list['diamond'];
    if (plan.hasOwnProperty('discountOptions') === false) return false;

    const setItem = (dt) =>  localStorage.setItem(SHOW_VIP_DISCOUNT, JSON.stringify(dt));

    const currentTimestamp = Date.now();
    const defaultData = {checkedAt: null, showedAt: null}
    const dataToParse = localStorage.getItem(SHOW_VIP_DISCOUNT) ?? null;
    const data = dataToParse ? JSON.parse(dataToParse) : defaultData;

    // Do not shop popup for 2 days after it was seen.
    const timeToPass = 2 * 24 * 60 * 60 * 1000;
    if (data.showedAt && timeToPass > (currentTimestamp - data.showedAt)) {
        return false;
    }

    data.showedAt = currentTimestamp;
    setItem(data);
    return true;
}

function useUserCheckForModals() {
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const isFetched = useSelector(state => state.auth.fetchOnInit);

    const checkForVerificationModal = () => {
        const showModal =
            user.sex === 'female' && !user.isVerified && !user.isVerificationPending &&
            !location.pathname.includes('/verification') &&
            !location.pathname.includes('/onboarding');

        if (!showModal) {
            return;
        }

        const currentTimestamp = Date.now();
        const timeShowed = localStorage.getItem(SHOW_VERIFY);

        // For the first time, just set the timestamp
        if (!timeShowed) {
            localStorage.setItem(SHOW_VERIFY, currentTimestamp.toString())

            // Show verify popup for the first time.
            // TODO. Disabled. AK-BLOCK-REVERSE
            // setTimeout(() => {
            //     dispatch(modalActions.open({name: 'verifyWeekly'}));
            // }, 2000);

            return;
        }

        // Check is last fixed time is more than 1 week.
        if (timeShowed) {
            const storedDate = new Date(Number(timeShowed));
            const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
            const isMoreThanOneWeek = (currentTimestamp - storedDate) > oneWeekInMilliseconds;

            // Log the result
            if (isMoreThanOneWeek) {
                localStorage.setItem(SHOW_VERIFY, currentTimestamp.toString())
                dispatch(modalActions.open({name: 'verifyWeekly'}));
            }
        }
    }

    useEffect(() => {
        if (!isFetched || !user) return;

        // Email confirmation
        if (!user.email.email || (user.email.email && !user.email.isConfirmed)) {
            dispatch(modalActions.open({name: 'emailValidation', disabled: true}));
            return;
        }

        // Suspended user screen/modal.
        if (user.sex === 'female' && user.isBlocked && !user.isSuspended &&
            !location.pathname.includes('/verification') && !user.isVerificationPending) {
            dispatch(modalActions.open({name: 'verifyBlocked', disabled: true}));
            return;
        }

        // Onboarding is required for all users after registration.
        if ((!user.onboardingCompleted || !user.sex) && location.pathname !== '/onboarding') {
            window.location = '/onboarding';
            return;
        }

        // Show preferences popup.
        if (localStorage.getItem(SHOW_PREFERENCES) && location.pathname !== '/onboarding') {
            setTimeout(() => {
                dispatch(modalActions.open({name: 'preferences'}));
            }, 3000);
            return;
        }

        // MALE VIP DISCOUNT OFFER
        if (showVipDiscountOffer(user, location.pathname)) {
            setTimeout(() => {
                dispatch(modalActions.open({name: 'vipDiscounted'}));
            }, 3000);
            return;
        }

        // Female diamond membership popup once a week.
        if (showDiamondMembershipReminder(user, location.pathname)) {
            setTimeout(() => {
                dispatch(modalActions.open({name: 'membershipFemaleDiamondReminder', data: {maxWidth: '380px'}}));
            }, 3000);
            return;
        }

        // Verify popup every 1week for girls
        checkForVerificationModal();

        // Verification renew popup
        if (user.sex === 'female' && user.id < 3471500 && !location.pathname.includes('/verification')) {
            axios.get('user/'+user.id+'/verification/renew').then((r) => {
                if (r.data.needsToRenewVerification) {
                    dispatch(modalActions.open({name: 'verifyRenew'}));
                }
            });
        }
    }, [user, isFetched, dispatch]);
}

export default useUserCheckForModals;
